import React from "react";
// import { Link } from 'react-router-dom';
import {Link} from 'react-router-dom'

import { useLocation } from "react-router-dom";
export default function Sidebar() {
     const location = useLocation();

    //destructuring pathname from location
    const { pathname } = location;

    //Javascript split method to get the name of the path in array
    const splitLocation = pathname.split("/");
    return (
        
        <div className="iq-sidebar">
            <div className="iq-navbar-logo d-flex justify-content-between">
                <a href="index.html" className="header-logo">
                    {/* <img src="images/logo.png" className="img-fluid rounded" alt="" /> */}
                    <span>welcome</span>
                </a>
                <div className="iq-menu-bt align-self-center">
                    <div className="wrapper-menu">
                        <div className="main-circle">
                            <i className="ri-menu-line"></i>
                        </div>
                        <div className="hover-circle">
                            <i className="ri-close-fill"></i>
                        </div>
                    </div>
                </div>
            </div>
            <div id="sidebar-scrollbar">
                <nav className="iq-sidebar-menu">
                    <ul id="iq-sidebar-toggle" className="iq-menu">
                        <li className="active">
                            {/* <ul>
                                <li className="active">
                                    <a href="index.html">
                                        <i className="las la-laptop-code"></i>Dashboard
                                    </a>
                                </li>
                            </ul> */}
                        </li>

                       <Link to="/">  <li className={splitLocation[1] === "" ? "active" : ""}>
                           
                            <a href="#userinfo" className="iq-waves-effect" data-toggle="collapse" aria-expanded="false">
                                <span className="ripple rippleEffect"></span>
                                <i class="las la-plus-circle iq-arrow-left"></i>
                                <span>Create Video </span>
                                <i className="ri-arrow-right-s-line iq-arrow-right"></i>
                            </a>

                            {/* <ul id="userinfo" className="iq-submenu collapse" data-parent="#iq-sidebar-toggle">
                                <li>
                                    <a href="profile.html">
                                        <i className="las la-id-card-alt"></i>User Profile
                                    </a>
                                </li>
                                <li>
                                    <a href="profile-edit.html">
                                        <i className="las la-edit"></i>User Edit
                                    </a>
                                </li>
                                <li>
                                    <a href="add-user.html">
                                        <i className="las la-plus-circle"></i>User Add
                                    </a>
                                </li>
                                <li>
                                    <a href="user-list.html">
                                        <i className="las la-th-list"></i>User List
                                    </a>
                                </li>
                            </ul> */}

                        </li></Link>
                            
                   <Link to="/video-library">    <li className={splitLocation[1] === "video-library" ? "active" : ""}> 
                            <a href="#ui-elements" className="iq-waves-effect collapsed" data-toggle="collapse" aria-expanded="false">
                               <i class="las la-th-large iq-arrow-left"></i>
                                <span>Video Library</span>
                                <i className="ri-arrow-right-s-line iq-arrow-right"></i>
                            </a>
                            {/* <ul id="ui-elements" className="iq-submenu collapse" data-parent="#iq-sidebar-toggle">
                                <li>
                                    <a href="ui-colors.html">
                                        <i className="las la-palette"></i>colors
                                    </a>
                                </li>
                                <li>
                                    <a href="ui-typography.html">
                                        <i className="las la-keyboard"></i>Typography
                                    </a>
                                </li>
                                <li>
                                    <a href="ui-alerts.html">
                                        <i className="las la-tag"></i>Alerts
                                    </a>
                                </li>
                                <li>
                                    <a href="ui-badges.html">
                                        <i className="lab la-atlassian"></i>Badges
                                    </a>
                                </li>
                                <li>
                                    <a href="ui-breadcrumb.html">
                                        <i className="las la-bars"></i>Breadcrumb
                                    </a>
                                </li>
                                <li>
                                    <a href="ui-buttons.html">
                                        <i className="las la-tablet"></i>Buttons
                                    </a>
                                </li>
                                <li>
                                    <a href="ui-cards.html">
                                        <i className="las la-credit-card"></i>Cards
                                    </a>
                                </li>
                                <li>
                                    <a href="ui-carousel.html">
                                        <i className="las la-film"></i>Carousel
                                    </a>
                                </li>
                                <li>
                                    <a href="ui-embed-video.html">
                                        <i className="las la-video"></i>Video
                                    </a>
                                </li>
                                <li>
                                    <a href="ui-grid.html">
                                        <i className="las la-border-all"></i>Grid
                                    </a>
                                </li>
                                <li>
                                    <a href="ui-images.html">
                                        <i className="las la-images"></i>Images
                                    </a>
                                </li>
                                <li>
                                    <a href="ui-list-group.html">
                                        <i className="las la-list"></i>list Group
                                    </a>
                                </li>
                                <li>
                                    <a href="ui-media-object.html">
                                        <i className="las la-ad"></i>Media
                                    </a>
                                </li>
                                <li>
                                    <a href="ui-modal.html">
                                        <i className="las la-columns"></i>Modal
                                    </a>
                                </li>
                                <li>
                                    <a href="ui-notifications.html">
                                        <i className="las la-bell"></i>Notifications
                                    </a>
                                </li>
                                <li>
                                    <a href="ui-pagination.html">
                                        <i className="las la-ellipsis-h"></i>Pagination
                                    </a>
                                </li>
                                <li>
                                    <a href="ui-popovers.html">
                                        <i className="las la-eraser"></i>Popovers
                                    </a>
                                </li>
                                <li>
                                    <a href="ui-progressbars.html">
                                        <i className="las la-hdd"></i>Progressbars
                                    </a>
                                </li>
                                <li>
                                    <a href="ui-tabs.html">
                                        <i className="las la-database"></i>Tabs
                                    </a>
                                </li>
                                <li>
                                    <a href="ui-tooltips.html">
                                        <i className="las la-magnet"></i>Tooltips
                                    </a>
                                </li>
                            </ul> */}

                        </li>
</Link>

                          {/* <hr /> */}
                        <Link to="/api-documentation">    <li className={splitLocation[1] === "api-documentation" ? "active" : ""}> 
                           
                            <a href="#forms" className="iq-waves-effect collapsed" data-toggle="collapse" aria-expanded="false">
                               <i class="las la-file-alt iq-arrow-left"></i>
                                <span>Api Documentation</span>
                                <i className="ri-arrow-right-s-line iq-arrow-right"></i>
                            </a>

                            {/* <ul id="forms" className="iq-submenu collapse" data-parent="#iq-sidebar-toggle">
                                <li>
                                    <a href="form-layout.html">
                                        <i className="las la-book"></i>Form Elements
                                    </a>
                                </li>
                                <li>
                                    <a href="form-validation.html">
                                        <i className="las la-edit"></i>Form Validation
                                    </a>
                                </li>
                                <li>
                                    <a href="form-switch.html">
                                        <i className="las la-toggle-off"></i>Form Switch
                                    </a>
                                </li>
                                <li>
                                    <a href="form-chechbox.html">
                                        <i className="las la-check-square"></i>Form Checkbox
                                    </a>
                                </li>
                                <li>
                                    <a href="form-radio.html">
                                        <i className="ri-radio-button-line"></i>Form Radio
                                    </a>
                                </li>
                            </ul> */}

                        </li> </Link>
                        <Link to="/support">    <li className={splitLocation[1] === "support" ? "active" : ""}>
                            <a href="#wizard-form" className="iq-waves-effect collapsed" data-toggle="collapse" aria-expanded="false">
                               <i class="las la-life-ring iq-arrow-left"></i>
                                {/* <i class="las la-life-ring"></i> */}
                                <span>Support</span>
                                <i className="ri-arrow-right-s-line iq-arrow-right"></i>
                            </a>

                            {/* <ul id="wizard-form" className="iq-submenu collapse" data-parent="#iq-sidebar-toggle">
                                <li>
                                    <a href="form-wizard.html">
                                        <i className="ri-clockwise-line"></i>Simple Wizard
                                    </a>
                                </li>
                                <li>
                                    <a href="form-wizard-validate.html">
                                        <i className="ri-clockwise-2-line"></i>Validate Wizard
                                    </a>
                                </li>
                                <li>
                                    <a href="form-wizard-vertical.html">
                                        <i className="ri-anticlockwise-line"></i>Vertical Wizard
                                    </a>
                                </li>
                            </ul> */}

                        </li></Link>
                        {/* <hr /> */}
                       <Link to="/pricing">    <li className={splitLocation[1] === "pricing" ? "active" : ""}>
                            <a href="#tables" className="iq-waves-effect collapsed" data-toggle="collapse" aria-expanded="false">
                               <i class="las la-dollar-sign iq-arrow-left"></i>
                                {/* <i class="las la-dollar-sign"></i> */}
                                <span>Pricing</span>
                                <i className="ri-arrow-right-s-line iq-arrow-right"></i>
                            </a>

                            {/* <ul id="tables" className="iq-submenu collapse" data-parent="#iq-sidebar-toggle">
                                <li>
                                    <a href="tables-basic.html">
                                        <i className="ri-table-line"></i>Basic Tables
                                    </a>
                                </li>
                                <li>
                                    <a href="data-table.html">
                                        <i className="ri-database-line"></i>Data Table
                                    </a>
                                </li>
                                <li>
                                    <a href="table-editable.html">
                                        <i className="ri-refund-line"></i>Editable Table
                                    </a>
                                </li>
                            </ul> */}

                        </li></Link>
                         <div class="container mt-5">
                            <div class="row">
                               
                                    <div class="col-md-9">
                                        
                                    </div>
                                    <div class="col-md-2">
                                        <i class="las la-redo"></i>
                                    </div>
                                    <div class="col-md-1">
                                        
                            
                                </div>
                            </div>
                         </div>

                       <Link to="/guest">    <li className={splitLocation[1] === "guest" ? "active" : ""}>
                            <a href="#charts" className=" mt-3 iq-waves-effect collapsed" data-toggle="collapse" aria-expanded="false">
                                <i className="las la-user-circle iq-arrow-left"></i>
                                {/* <i class="las la-user-circle"></i> */}
                                <span>Guest</span>
                                <i className="ri-arrow-down-s-line iq-arrow-right"></i>
                            </a>
                             
                            {/* <ul id="" className="iq-submenu collapse" data-parent="#iq-sidebar-toggle">
                                
                               
                                <li>
                                    <a href="chart-apex.html">
                                        <i className="ri-folder-chart-2-line"></i>Apex Chart
                                    </a>
                                </li>
                               
                            </ul> */}
                        </li></Link>

                        {/* <li>
                            <a href="#icons" className="iq-waves-effect collapsed" data-toggle="collapse" aria-expanded="false">
                                <i className="ri-list-check iq-arrow-left"></i>
                                <span>Icons</span>
                                <i className="ri-arrow-right-s-line iq-arrow-right"></i>
                            </a>
                            <ul id="icons" className="iq-submenu collapse" data-parent="#iq-sidebar-toggle">
                                <li>
                                    <a href="icon-dripicons.html">
                                        <i className="ri-stack-line"></i>Dripicons
                                    </a>
                                </li>
                                <li>
                                    <a href="icon-fontawesome-5.html">
                                        <i className="ri-facebook-fill"></i>Font Awesome 5
                                    </a>
                                </li>
                                <li>
                                    <a href="icon-lineawesome.html">
                                        <i className="ri-keynote-line"></i>line Awesome
                                    </a>
                                </li>
                                <li>
                                    <a href="icon-remixicon.html">
                                        <i className="ri-remixicon-line"></i>Remixicon
                                    </a>
                                </li>
                                <li>
                                    <a href="icon-unicons.html">
                                        <i className="ri-underline"></i>unicons
                                    </a>
                                </li>
                            </ul>
                        </li>
                        <li>
                            <a href="#authentication" className="iq-waves-effect collapsed" data-toggle="collapse" aria-expanded="false">
                                <i className="ri-pages-line iq-arrow-left"></i>
                                <span>Authentication</span>
                                <i className="ri-arrow-right-s-line iq-arrow-right"></i>
                            </a>
                            <ul id="authentication" className="iq-submenu collapse" data-parent="#iq-sidebar-toggle">
                                <li>
                                    <a href="sign-in.html">
                                        <i className="las la-sign-in-alt"></i>Login
                                    </a>
                                </li>
                                <li>
                                    <a href="sign-up.html">
                                        <i className="ri-login-circle-line"></i>Register
                                    </a>
                                </li>
                                <li>
                                    <a href="pages-recoverpw.html">
                                        <i className="ri-record-mail-line"></i>Recover Password
                                    </a>
                                </li>
                                <li>
                                    <a href="pages-confirm-mail.html">
                                        <i className="ri-file-code-line"></i>Confirm Mail
                                    </a>
                                </li>
                                <li>
                                    <a href="pages-lock-screen.html">
                                        <i className="ri-lock-line"></i>Lock Screen
                                    </a>
                                </li>
                            </ul>
                        </li>
                        <li>
                            <a href="#extra-pages" className="iq-waves-effect collapsed" data-toggle="collapse" aria-expanded="false">
                                <i className="ri-pantone-line iq-arrow-left"></i>
                                <span>Extra Pages</span>
                                <i className="ri-arrow-right-s-line iq-arrow-right"></i>
                            </a>
                            <ul id="extra-pages" className="iq-submenu collapse" data-parent="#iq-sidebar-toggle">
                                <li>
                                    <a href="pages-timeline.html">
                                        <i className="ri-map-pin-time-line"></i>Timeline
                                    </a>
                                </li>
                                <li>
                                    <a href="pages-invoice.html">
                                        <i className="ri-question-answer-line"></i>Invoice
                                    </a>
                                </li>
                                <li>
                                    <a href="blank-page.html">
                                        <i className="ri-invision-line"></i>Blank Page
                                    </a>
                                </li>
                                <li>
                                    <a href="pages-error.html">
                                        <i className="ri-error-warning-line"></i>Error 404
                                    </a>
                                </li>
                                <li>
                                    <a href="pages-error-500.html">
                                        <i className="ri-error-warning-line"></i>Error 500
                                    </a>
                                </li>
                                <li>
                                    <a href="pages-pricing.html">
                                        <i className="ri-price-tag-line"></i>Pricing
                                    </a>
                                </li>
                                <li>
                                    <a href="pages-pricing-one.html">
                                        <i className="ri-price-tag-2-line"></i>Pricing 1
                                    </a>
                                </li>
                                <li>
                                    <a href="pages-maintenance.html">
                                        <i className="ri-archive-line"></i>Maintenance
                                    </a>
                                </li>
                                <li>
                                    <a href="pages-comingsoon.html">
                                        <i className="ri-mastercard-line"></i>Coming Soon
                                    </a>
                                </li>
                                <li>
                                    <a href="pages-faq.html">
                                        <i className="ri-compasses-line"></i>Faq
                                    </a>
                                </li>
                            </ul>
                        </li>
                        <li>
                            <a href="#menu-level" className="iq-waves-effect collapsed" data-toggle="collapse" aria-expanded="false">
                                <i className="ri-record-circle-line iq-arrow-left"></i>
                                <span>Menu Level</span>
                                <i className="ri-arrow-right-s-line iq-arrow-right"></i>
                            </a>
                            <ul id="menu-level" className="iq-submenu collapse" data-parent="#iq-sidebar-toggle">
                                <li>
                                    <a href="#">
                                        <i className="ri-record-circle-line"></i>Menu 1
                                    </a>
                                </li>
                                <li>
                                    <a href="#">
                                        <i className="ri-record-circle-line"></i>Menu 2
                                    </a>
                                </li>
                                <li>
                                    <a href="#">
                                        <i className="ri-record-circle-line"></i>Menu 3
                                    </a>
                                </li>
                                <li>
                                    <a href="#">
                                        <i className="ri-record-circle-line"></i>Menu 4
                                    </a>
                                </li>
                            </ul>
                        </li> */}

                    </ul>
                </nav>
                <div className="p-3"></div>
            </div>
        </div>
    );
}
