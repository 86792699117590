import React, { useState, useRef } from "react";
import "./videolibrary.css";

export default function VideoLibrary() {
    const [isPlaying, setIsPlaying] = useState(false);
    const videoRef = useRef(null);

    const handlePlayPause = () => {
        setIsPlaying(!isPlaying);
        if (!isPlaying) {
            videoRef.current.play();
        } else {
            videoRef.current.pause();
        }
    };

    return (
        <div>
            <div id="content-page" className="content-page">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-sm-4">
                            <div className="card iq-mb-3 zoom itemsContainer">
                                <div className="image">
                                    {isPlaying ? (
                                        <video
                                            ref={videoRef}
                                            src="/videos/videojason.mp4"
                                            //  width={'520px'}
                                            //  height={'240px'}
                                            className="card-video"
                                            controls
                                        />
                                    ) : (
                                        <video
                                            ref={videoRef}
                                            src="/videos/videojason.mp4"
                                            className="card-img-top"
                                            poster="/videos/videojason.png" // Add the video thumbnail here
                                            onClick={handlePlayPause}
                                        />
                                    )}
                                </div>
                                <div className="play" onClick={handlePlayPause}>
                                    {isPlaying ? null : <img src="/images/page-img/playicon.png" />}
                                </div>
                                <div className="card-body overflocss">
                                    <div className="row">
                                        <div className="col-10">
                                            <h5 className="card-title">AI Video &gt; How to create video for Beginners.</h5>
                                        </div>
                                        <div className="col-2 pl-0">
                                            <div className="iq-card-post-toolbar">
                                                <div className="dropdown">
                                                    <span className="dropdown-toggle" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" role="button">
                                                        <i className="ri-more-fill"></i>
                                                    </span>
                                                    <div className="dropdown-menu m-0 p-0">
                                                        <a className="dropdown-item p-3" href="#">
                                                            <div className="d-flex align-items-top">
                                                                <div className="data ml-2">
                                                                    <p>
                                                                        <i className="las la-cloud-download-alt"></i> Download
                                                                    </p>
                                                                    <p>
                                                                        <i className="ri-share-forward-line"></i> Share
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        </a>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-sm-4">
                            <div class="card iq-mb-3 zoom itemsContainer">
                                <div class="image">
                                    {" "}
                                    <a href="#">
                                        <img src="images/page-img/n2.avif" class="card-img-top" alt="#" />
                                    </a>
                                </div>
                                <div class="play">
                                    <img src="/images/page-img/playicon.png" />
                                </div>
                                <div class="card-body">
                                    <div class="row">
                                        <div class="col-10">
                                            <h5 class="card-title">Create Video &gt; How to create video for Beginners.</h5>
                                            {/* <p class="card-text m-0">UTech <i class="las la-check-circle"></i> </p>
                          <p>1M views &bull; 5 months ago</p> */}
                                        </div>
                                        <div class="col-2 pl-0">
                                            <div class="iq-card-post-toolbar">
                                                <div class="dropdown">
                                                    <span class="dropdown-toggle" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" role="button">
                                                        <i class="ri-more-fill"></i>
                                                    </span>
                                                    <div class="dropdown-menu m-0 p-0">
                                                        <a class="dropdown-item p-3" href="#">
                                                            <div class="d-flex align-items-top">
                                                                {/* <div class="icon font-size-20"><i class="ri-save-line"></i></div> */}
                                                                <div class="data ml-2">
                                                                    <p>
                                                                        <i class="las la-cloud-download-alt"></i> Download
                                                                    </p>
                                                                    <p>
                                                                        <i class="ri-share-forward-line"></i> Share
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        </a>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-sm-4">
                            <div class="card iq-mb-3 zoom itemsContainer">
                                <div class="image">
                                    {" "}
                                    <a href="#">
                                        <img src="images/page-img/n3.jpg" class="card-img-top" alt="#" />
                                    </a>
                                </div>
                                <div class="play">
                                    <img src="/images/page-img/playicon.png" />
                                </div>
                                <div class="card-body">
                                    <div class="row">
                                        <div class="col-10">
                                            <h5 class="card-title">Create Video &gt; How to create video for Beginners.</h5>
                                            {/* <p class="card-text m-0">UTech <i class="las la-check-circle"></i> </p>
                          <p>1M views &bull; 5 months ago</p> */}
                                        </div>
                                        <div class="col-2 pl-0">
                                            <div class="iq-card-post-toolbar">
                                                <div class="dropdown">
                                                    <span class="dropdown-toggle" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" role="button">
                                                        <i class="ri-more-fill"></i>
                                                    </span>
                                                    <div class="dropdown-menu m-0 p-0">
                                                        <a class="dropdown-item p-3" href="#">
                                                            <div class="d-flex align-items-top">
                                                                {/* <div class="icon font-size-20"><i class="ri-save-line"></i></div> */}
                                                                <div class="data ml-2">
                                                                    <p>
                                                                        <i class="las la-cloud-download-alt"></i> Download
                                                                    </p>
                                                                    <p>
                                                                        <i class="ri-share-forward-line"></i> Share
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        </a>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-sm-4">
                            <div class="card iq-mb-3 zoom itemsContainer">
                                <div class="image">
                                    {" "}
                                    <a href="#">
                                        <img src="images/page-img/n5.avif" class="card-img-top" alt="#" />
                                    </a>
                                </div>
                                <div class="play">
                                    <img src="/images/page-img/playicon.png" />
                                </div>
                                <div class="card-body">
                                    <div class="row">
                                        <div class="col-10">
                                            <h5 class="card-title">Create Video &gt; How to create video for Beginners.</h5>
                                            {/* <p class="card-text m-0">UTech <i class="las la-check-circle"></i> </p>
                          <p>1M views &bull; 5 months ago</p> */}
                                        </div>
                                        <div class="col-2 pl-2">
                                            <div class="iq-card-post-toolbar">
                                                <div class="dropdown">
                                                    <span class="dropdown-toggle" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" role="button">
                                                        <i class="ri-more-fill"></i>
                                                    </span>
                                                    <div class="dropdown-menu m-0 p-0">
                                                        <a class="dropdown-item p-3" href="#">
                                                            <div class="d-flex align-items-top">
                                                                {/* <div class="icon font-size-20"><i class="ri-save-line"></i></div> */}
                                                                <div class="data ml-2">
                                                                    <p>
                                                                        <i class="las la-cloud-download-alt"></i> Download
                                                                    </p>
                                                                    <p>
                                                                        <i class="ri-share-forward-line"></i> Share
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        </a>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-sm-4">
                            <div class="card iq-mb-3 zoom itemsContainer">
                                <div class="image">
                                    {" "}
                                    <a href="#">
                                        <img src="images/page-img/n1.jpg" class="card-img-top" alt="#" />
                                    </a>
                                </div>
                                <div class="play">
                                    <img src="/images/page-img/playicon.png" />
                                </div>
                                <div class="card-body">
                                    <div class="row">
                                        <div class="col-10">
                                            <h5 class="card-title">Create Video &gt; How to create video for Beginners.</h5>
                                            {/* <p class="card-text m-0">UTech <i class="las la-check-circle"></i> </p>
                          <p>1M views &bull; 5 months ago</p> */}
                                        </div>
                                        <div class="col-2 pl-0">
                                            <div class="iq-card-post-toolbar">
                                                <div class="dropdown">
                                                    <span class="dropdown-toggle" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" role="button">
                                                        <i class="ri-more-fill"></i>
                                                    </span>
                                                    <div class="dropdown-menu m-0 p-0">
                                                        <a class="dropdown-item p-3" href="#">
                                                            <div class="d-flex align-items-top">
                                                                {/* <div class="icon font-size-20"><i class="ri-save-line"></i></div> */}
                                                                <div class="data ml-2">
                                                                    <p>
                                                                        <i class="las la-cloud-download-alt"></i> Download
                                                                    </p>
                                                                    <p>
                                                                        <i class="ri-share-forward-line"></i> Share
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        </a>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-sm-4">
                            <div class="card iq-mb-3 zoom itemsContainer">
                                <div class="image">
                                    {" "}
                                    <a href="#">
                                        <img src="images/page-img/n6.png" style={{ background: "#dee2e6" }} class="card-img-top" alt="#" />
                                    </a>
                                </div>
                                <div class="play">
                                    <img src="/images/page-img/playicon.png" />
                                </div>
                                <div class="card-body">
                                    <div class="row">
                                        <div class="col-10">
                                            <h5 class="card-title">Create Video &gt; How to create video for Beginners.</h5>
                                            {/* <p class="card-text m-0">UTech <i class="las la-check-circle"></i> </p>
                          <p>1M views &bull; 5 months ago</p> */}
                                        </div>
                                        <div class="col-2 pl-0">
                                            <div class="iq-card-post-toolbar">
                                                <div class="dropdown">
                                                    <span class="dropdown-toggle" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" role="button">
                                                        <i class="ri-more-fill"></i>
                                                    </span>
                                                    <div class="dropdown-menu m-0 p-0">
                                                        <a class="dropdown-item p-3" href="#">
                                                            <div class="d-flex align-items-top">
                                                                {/* <div class="icon font-size-20"><i class="ri-save-line"></i></div> */}
                                                                <div class="data ml-2">
                                                                    <p>
                                                                        <i class="las la-cloud-download-alt"></i> Download
                                                                    </p>
                                                                    <p>
                                                                        <i class="ri-share-forward-line"></i> Share
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        </a>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
