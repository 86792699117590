// import $ from 'jquery'; // Import jQuery

import React, { useState } from "react";

export default function Navbar() {

    
    // below is also working code for show video in place of banner image using jquery
//    const [isVideoShown, setIsVideoShown] = useState(false);

//   const handleGenerateVideo = () => {
//     // Show the video and hide the banner image using jQuery
//     setIsVideoShown(true);
//     $('#bannerImage').hide();
//     $('#bannerVideo').show();

//     // Play the video
//     const video = document.getElementById('bannerVideo');
//     video.play();
//   };
// above  is also working code for show video in place of banner image using jquery

// start code for play video in place of banner image
const [isVideoShown, setIsVideoShown] = useState(false);

  const handleGenerateVideo = () => {
    setIsVideoShown(true);

    // Hide the banner image and show the video using vanilla JavaScript
    const bannerImage = document.getElementById('bannerImage');
    const bannerVideo = document.getElementById('bannerVideo');

    if (bannerImage && bannerVideo) {
      bannerImage.style.display = 'none';
      bannerVideo.style.display = 'block';
      bannerVideo.play();
    }
  };
    // end code for play video in place of banner image


    return (
        <div className="iq-top-navbar">
            <div className="iq-navbar-custom">
                <nav className="navbar navbar-expand-lg navbar-light p-0">
                    <div className="iq-menu-bt d-flex align-items-center">
                        <div className="wrapper-menu">
                            <div className="main-circle">
                                <i className="ri-menu-line"></i>
                            </div>
                            <div className="hover-circle">
                                <i className="ri-close-fill"></i>
                            </div>
                        </div>
                        {/* <div className="iq-navbar-logo d-flex justify-content-between ml-3">
                            <a href="index.html" className="header-logo">
                                <img src="images/logo.png" className="img-fluid rounded" alt="" />
                                <span>FinDash</span>
                            </a>
                        </div> */}
                    </div>
                    <div className="iq-search-bar">
                        {/* <form action="#" className="searchbox">
                            <input type="text" className="text search-input" placeholder="Type here to search..." />
                            <a className="search-link" href="#">
                                <i class="ri-search-line"></i>
                            </a>
                        </form> */}
                        <h3>AI</h3>
                    </div>
                    <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-label="Toggle navigation">
                        <i className="ri-menu-3-line"></i>
                    </button>
                    <div className="collapse navbar-collapse" id="navbarSupportedContent">
                        <ul className="navbar-nav ml-auto navbar-list">
                            {/* <li className="nav-item">
                                <a className="search-toggle iq-waves-effect language-title" href="#">
                                    <span className="ripple rippleEffect" style="width: 98px; height: 98px; top: -15px; left: 56.2969px;"></span>
                                    <img src="images/small/flag-01.png" alt="img-flaf" class="img-fluid mr-1" style="height: 16px; width: 16px;" /> EN <i class="ri-arrow-down-s-line"></i>
                                </a>
                                <div class="iq-sub-dropdown">
                                    <a class="iq-sub-card" href="#">
                                        <img src="images/small/flag-02.png" alt="img-flaf" class="img-fluid mr-2" />
                                        French
                                    </a>
                                    <a class="iq-sub-card" href="#">
                                        <img src="images/small/flag-03.png" alt="img-flaf" class="img-fluid mr-2" />
                                        Spanish
                                    </a>
                                    <a class="iq-sub-card" href="#">
                                        <img src="images/small/flag-04.png" alt="img-flaf" class="img-fluid mr-2" />
                                        Italian
                                    </a>
                                    <a class="iq-sub-card" href="#">
                                        <img src="images/small/flag-05.png" alt="img-flaf" class="img-fluid mr-2" />
                                        German
                                    </a>
                                    <a class="iq-sub-card" href="#">
                                        <img src="images/small/flag-06.png" alt="img-flaf" class="img-fluid mr-2" />
                                        Japanese
                                    </a>
                                </div>
                            </li> */}
                            <li className="nav-item nav-icon">
                                {/* <a href="#" className="iq-waves-effect bg-primary rounded">
                                    <span className="ripple rippleEffect"></span>
                                    <i className="ri-shopping-cart-2-line"></i>
                                </a> */}
                                <button type="button" class="btn btn-outline-primary mt-3 m-1"><i class="las la-video-slash"></i>DISCARD VIDEO </button>&nbsp;&nbsp;
                            </li>
                          <li className="nav-item nav-icon">
        <button
          type="button"
          class="btn btn-outline-info mt-3 m-1"
          onClick={handleGenerateVideo}
        >
          <i class="las la-video"></i> GENERATE VIDEO
        </button>

                                {/* <a href="#" className="search-toggle iq-waves-effect bg-primary rounded">
                                    <i className="ri-notification-line"></i>
                                    <span className="bg-danger dots"></span>
                                </a>
                                <div className="iq-sub-dropdown">
                                    <div className="iq-card shadow-none m-0">
                                        <div className="iq-card-body p-0 ">
                                            <div className="bg-primary p-3">
                                                <h5 className="mb-0 text-white">
                                                    All Notifications<small class="badge  badge-light float-right pt-1">4</small>
                                                </h5>
                                            </div>
                                            <a href="#" className="iq-sub-card">
                                                <div className="media align-items-center">
                                                    <div className="">
                                                        <img className="avatar-40 rounded" src="images/user/01.jpg" alt="" />
                                                    </div>
                                                    <div className="media-body ml-3">
                                                        <h6 className="mb-0 ">Emma Watson Barry</h6>
                                                        <small className="float-right font-size-12">Just Now</small>
                                                        <p className="mb-0">95 MB</p>
                                                    </div>
                                                </div>
                                            </a>
                                            <a href="#" className="iq-sub-card">
                                                <div className="media align-items-center">
                                                    <div className="">
                                                        <img className="avatar-40 rounded" src="images/user/02.jpg" alt="" />
                                                    </div>
                                                    <div className="media-body ml-3">
                                                        <h6 className="mb-0 ">New customer is join</h6>
                                                        <small className="float-right font-size-12">5 days ago</small>
                                                        <p class="mb-0">Cyst Barry</p>
                                                    </div>
                                                </div>
                                            </a>
                                            <a href="#" className="iq-sub-card">
                                                <div className="media align-items-center">
                                                    <div className="">
                                                        <img className="avatar-40 rounded" src="images/user/03.jpg" alt="" />
                                                    </div>
                                                    <div className="media-body ml-3">
                                                        <h6 className="mb-0 ">Two customer is left</h6>
                                                        <small className="float-right font-size-12">2 days ago</small>
                                                        <p class="mb-0">Cyst Barry</p>
                                                    </div>
                                                </div>
                                            </a>
                                            <a href="#" className="iq-sub-card">
                                                <div className="media align-items-center">
                                                    <div className="">
                                                        <img className="avatar-40 rounded" src="images/user/04.jpg" alt="" />
                                                    </div>
                                                    <div className="media-body ml-3">
                                                        <h6 className="mb-0 ">New Mail from Fenny</h6>
                                                        <small className="float-right font-size-12">3 days ago</small>
                                                        <p className="mb-0">Cyst Barry</p>
                                                    </div>
                                                </div>
                                            </a>
                                        </div>
                                    </div>
                                </div> */}
                                
                            </li>
                            {/* <li className="nav-item nav-icon dropdown">
                                <a href="#" className="search-toggle iq-waves-effect bg-primary rounded">
                                    <i className="ri-mail-line"></i>
                                    <span className="bg-danger count-mail"></span>
                                </a>
                                <div className="iq-sub-dropdown">
                                    <div className="iq-card shadow-none m-0">
                                        <div className="iq-card-body p-0 ">
                                            <div className="bg-primary p-3">
                                                <h5 className="mb-0 text-white">
                                                    All Messages<small className="badge  badge-light float-right pt-1">5</small>
                                                </h5>
                                            </div>
                                            <a href="#" className="iq-sub-card">
                                                <div className="media align-items-center">
                                                    <div className="">
                                                        <img className="avatar-40 rounded" src="images/user/01.jpg" alt="" />
                                                    </div>
                                                    <div className="media-body ml-3">
                                                        <h6 className="mb-0 ">Barry Emma Watson</h6>
                                                        <small className="float-left font-size-12">13 Jun</small>
                                                    </div>
                                                </div>
                                            </a>
                                            <a href="#" className="iq-sub-card">
                                                <div className="media align-items-center">
                                                    <div className="">
                                                        <img className="avatar-40 rounded" src="images/user/02.jpg" alt="" />
                                                    </div>
                                                    <div className="media-body ml-3">
                                                        <h6 className="mb-0 ">Lorem Ipsum Watson</h6>
                                                        <small className="float-left font-size-12">20 Apr</small>
                                                    </div>
                                                </div>
                                            </a>
                                            <a href="#" className="iq-sub-card">
                                                <div className="media align-items-center">
                                                    <div className="">
                                                        <img className="avatar-40 rounded" src="images/user/03.jpg" alt="" />
                                                    </div>
                                                    <div className="media-body ml-3">
                                                        <h6 className="mb-0 ">Why do we use it?</h6>
                                                        <small className="float-left font-size-12">30 Jun</small>
                                                    </div>
                                                </div>
                                            </a>
                                            <a href="#" className="iq-sub-card">
                                                <div className="media align-items-center">
                                                    <div className="">
                                                        <img className="avatar-40 rounded" src="images/user/04.jpg" alt="" />
                                                    </div>
                                                    <div className="media-body ml-3">
                                                        <h6 className="mb-0 ">Variations Passages</h6>
                                                        <small className="float-left font-size-12">12 Sep</small>
                                                    </div>
                                                </div>
                                            </a>
                                            <a href="#" className="iq-sub-card">
                                                <div className="media align-items-center">
                                                    <div className="">
                                                        <img className="avatar-40 rounded" src="images/user/05.jpg" alt="" />
                                                    </div>
                                                    <div className="media-body ml-3">
                                                        <h6 className="mb-0 ">Lorem Ipsum generators</h6>
                                                        <small className="float-left font-size-12">5 Dec</small>
                                                    </div>
                                                </div>
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </li> */}
                        </ul>
                    </div>
                    <ul className="navbar-list mt-2">
                        <li className="line-height">
                            <a href="#" className="search-toggle iq-waves-effect d-flex align-items-center">
                                <img src="/images/page-img/22.jpg" className="img-fluid rounded mr-3" alt="user" />
                                <div className="caption">
                                    <h6 className="mb-0 line-height">Jason Stewart</h6>
                                    <p className="mb-0">Manager</p>
                                </div>
                            </a>
                            {/* <div className="iq-sub-dropdown iq-user-dropdown">
                                <div className="iq-card shadow-none m-0">
                                    <div className="iq-card-body p-0 ">
                                        <div className="bg-primary p-3">
                                            <h5 className="mb-0 text-white line-height">Hello Barry Tech</h5>
                                            <span className="text-white font-size-12">Available</span>
                                        </div>
                                        <a href="profile.html" className="iq-sub-card iq-bg-primary-hover">
                                            <div className="media align-items-center">
                                                <div className="rounded iq-card-icon iq-bg-primary">
                                                    <i className="ri-file-user-line"></i>
                                                </div>
                                                <div className="media-body ml-3">
                                                    <h6 className="mb-0 ">My Profile</h6>
                                                    <p className="mb-0 font-size-12">View personal profile details.</p>
                                                </div>
                                            </div>
                                        </a>
                                        <a href="profile-edit.html" className="iq-sub-card iq-bg-primary-hover">
                                            <div className="media align-items-center">
                                                <div className="rounded iq-card-icon iq-bg-primary">
                                                    <i className="ri-profile-line"></i>
                                                </div>
                                                <div className="media-body ml-3">
                                                    <h6 className="mb-0 ">Edit Profile</h6>
                                                    <p className="mb-0 font-size-12">Modify your personal details.</p>
                                                </div>
                                            </div>
                                        </a>
                                        <a href="account-setting.html" className="iq-sub-card iq-bg-primary-hover">
                                            <div className="media align-items-center">
                                                <div className="rounded iq-card-icon iq-bg-primary">
                                                    <i className="ri-account-box-line"></i>
                                                </div>
                                                <div className="media-body ml-3">
                                                    <h6 className="mb-0 ">Account settings</h6>
                                                    <p className="mb-0 font-size-12">Manage your account parameters.</p>
                                                </div>
                                            </div>
                                        </a>
                                        <a href="privacy-setting.html" className="iq-sub-card iq-bg-primary-hover">
                                            <div className="media align-items-center">
                                                <div className="rounded iq-card-icon iq-bg-primary">
                                                    <i className="ri-lock-line"></i>
                                                </div>
                                                <div className="media-body ml-3">
                                                    <h6 className="mb-0 ">Privacy Settings</h6>
                                                    <p className="mb-0 font-size-12">Control your privacy parameters.</p>
                                                </div>
                                            </div>
                                        </a>
                                        <div className="d-inline-block w-100 text-center p-3">
                                            <a className="bg-primary iq-sign-btn" href="sign-in.html" role="button">
                                                Sign out<i className="ri-login-box-line ml-2"></i>
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div> */}
                        </li>
                    </ul>
                </nav>
            </div>
        </div>
    );
}
