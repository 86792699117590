
import{ BrowserRouter, Routes, Route} from 'react-router-dom';

import Navbar from "./Components/Navbar";
import Sidebar from "./Components/Sidebar";
import Dashboard from "./Components/Dashboard";
import Footer from "./Components/Footer";
import VideoLibrary from "./Components/VideoLibrary";

function App() {
    return (
        <>
             <BrowserRouter>
              <Navbar />
          <Sidebar />
             <Routes>
                <Route exact path="/" element={<Dashboard />} />
                <Route path="/video-library" element={<VideoLibrary />} />

                
             </Routes>
             <Footer />
             </BrowserRouter>
            
        </>
    );
}

export default App;
