import React from "react";
import "./dashboard.css";
import { useState } from "react";
export default function Dashboard() {
       // below code for play video on  banner 
     const [isVideoShown, setIsVideoShown] = useState(false);
    // above code for play video on  banner 

    // start js for show hide choose presenter and generate AI presenter image and tab
    const [isShown1, setIsShown1] = useState(false);
    const [isShown, setIsShown] = useState(true);

    const handleClick1 = (event) => {
        // 👇️ toggle shown state
        setIsShown1((current) => !current);

        // 👇️ or simply set it to true
        setIsShown1(true);
        setIsShown(false);
    };
    const handleClick = (event) => {
        // 👇️ toggle shown state
        setIsShown((current) => !current);
        setIsShown1(false);
        setIsShown(true);

        // 👇️ or simply set it to true
        // setIsShown(true);
    };

    // End js for show hide choose presenter and generate AI presenter image and tab

    return (
        <div>
            <div class="wrapper">
                <div id="content-page" class="content-page">
                    <div class="container-fluid">
                        <div class="row">

                            {/* <div class="col-lg-8">
                    
                  </div> */}
                            {/* <div class="col-lg-4">
                     <div class="iq-card iq-card-block iq-card-stretch iq-card-height banner-image-block-bg position-relative" style="background: transparent url(images/page-img/45.png) no-repeat scroll center bottom; background-size: contain; height: 440px; box-shadow: none;">
                     </div>
                  </div> */}

                            <div class="col-lg-8">
                                <div class="iq-card iq-card-block iq-card-stretch iq-card-height">
                                    {isShown && (
                                        <div class="iq-card-header d-flex justify-content-between">
                                            <div class="iq-header-title">
                                                <h4 class="card-title">
                                                    New creative video<i class="las la-pencil-alt"></i>
                                                </h4>
                                            </div>
                                        </div>
                                    )}

                                    {/* Generate AI presenter tabs code start*/}
                                    {isShown1 && (
                                        <div class="container">
                                            <center>
                                                <h3 class="mt-5 mb-3">Generate an AI Presenter</h3>
                                                <div class="btn-group generate_ai mb-5" role="group" aria-label="Basic example">
                                                    <button type="button" class="btn btn-white">
                                                        A portrait of
                                                    </button>
                                                 
                                                   
                                                      <input type="text"
                                                        class="generateclick" name="" id="" aria-describedby="helpId" placeholder=" Click here for inspiration, or describe the present.." />
                                                      
                                                  
                                                    {/* <button type="button" class="btn btn-white" style={{ fontWeight: "lighter" }}>
                                                        Click here for inspiration, or describe the present..
                                                    </button> */}
                                                    <button type="button" class="btn btn-white">
                                                        Generate
                                                    </button>
                                                </div>
                                            </center>
                                        </div>
                                    )}

                                    {/* Generate AI presenter tbas code end*/}
                                    {/* Choose presenter img code start*/}

                                     <div className="row imgshow">
      <div className="col-sm-2"></div>
      <div className="col-sm-8">
        <div className="card iq-mb-2 m-1 mt-2 border-primary img_card">
          <div
            className="d-flex"
            style={{ justifyContent: 'center', position: 'relative' }}
          >
            <img
              id="bannerImage"
              src="/images/page-img/banner.png"
            //   className={isVideoShown ? 'hidden' : ''}
            className=""
              alt="person_img"
            />
            <video
              id="bannerVideo"
            //   className={`bannerVideo ${isVideoShown ? '' : 'hidden'}`}
            className=""
              controls
              style={{ width: '100%', height: 'auto',display:'none' }}
            >
              <source src="/videos/videojason.mp4" type="video/mp4" />
              Your browser does not support the video tag.
            </video>
            <div className="circle1">
              <div className="circle ">
                <span>BG</span>
              </div>
            </div>
            <div className="circle1 ml-1">
              <div className="squre">
                <span>Wide</span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="col-sm-2"></div>
      </div>
 
                                    {/* Choose presenter img code end*/}

                                    <div class="container">
                                        <div class="row">
                                            <div class="col-1"></div>
                                            <div class="col-10">
                                                <div class="iq-card-body">
                                                    <ul class="nav nav-tabs" id="myTab-1" role="tablist">
                                                        <li class="nav-item">
                                                            <a class="nav-link active" onClick={handleClick} id="home-tab" data-toggle="tab" href="#home" role="tab" aria-controls="home" aria-selected="true">
                                                                <b>
                                                                    <i class="las la-user"></i> Choose a presenter
                                                                </b>
                                                            </a>
                                                        </li>
                                                        <li class="nav-item">
                                                            <a class="nav-link" onClick={handleClick1} id="profile-tab" data-toggle="tab" href="#profile" role="tab" aria-controls="profile" aria-selected="false">
                                                                <b>
                                                                    <i class="las la-atom"></i> Generate AI presenter
                                                                </b>{" "}
                                                                <span class="badge badge_css text-white">New</span>
                                                            </a>
                                                        </li>

                                                        <li class="nav-item">
                                                            <a class="nav-link " id="contact-tab" data-toggle="tab" href="#contact" role="tab" aria-controls="contact" aria-selected="false">
                                                                0 / 15 Used
                                                            </a>
                                                        </li>
                                                    </ul>

                                                    <div class="tab-content" id="myTabContent-2">
                                                        <div class="tab-pane fade show active choosepresnt scrollbehve1 " id="home" role="tabpanel" aria-labelledby="home-tab">
                                                            <div class="container">
                                                                <div class="row">
                                                                    <div class="col-md-11 ml-2 d-flex">
                                                                        <div class="col-xs-1-12">
                                                                            <div class="add_plus">
                                                                                <i class="las la-plus">
                                                                                    <br></br>
                                                                                    <small>ADD</small>
                                                                                </i>
                                                                            </div>
                                                                        </div>

                                                                        <div class="imgcircle">
                                                                            <div class="img_boreder_circle ">HQ</div>
                                                                            <img src="images/page-img/v1.png" class="img-thumbnail m-2" alt="Responsive image" style={{border: '2px solid #ff6d00'}} />
                                                                        </div>
                                                                        <div class="imgcircle">
                                                                            <div class="img_boreder ">HQ</div>
                                                                            <img src="images/page-img/21.jpg" class="img-thumbnail m-2" alt="Responsive image" />
                                                                        </div>
                                                                        <div class="imgcircle">
                                                                            <img src="images/page-img/22.jpg" class="img-thumbnail m-2" alt="Responsive image" />
                                                                        </div>
                                                                    </div>
                                                                    <div class="col-md-1"></div>
                                                                </div>
                                                            </div>
                                                            <div class="container">
                                                                <div class="row">
                                                                    <div class="col-11 d-flex ">
                                                                        <div class="imgcircle">
                                                                            <img src="images/page-img/16.jpg" class="img-thumbnail m-2" alt="Responsive image" />
                                                                        </div>
                                                                        <div class="imgcircle">
                                                                            <img src="images/page-img/19.jpg" class="img-thumbnail m-2" alt="Responsive image" />
                                                                        </div>
                                                                        <div class="imgcircle">
                                                                            <img src="images/page-img/18.jpg" class="img-thumbnail m-2" alt="Responsive image" />
                                                                        </div>
                                                                        <div class="imgcircle">
                                                                            <img src="images/page-img/23.jpg" class="img-thumbnail m-2" alt="Responsive image" />
                                                                        </div>
                                                                    </div>
                                                                    <div class="col-1"></div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="tab-pane fade  choosepresnt scrollbehve1" id="profile" role="tabpanel" aria-labelledby="profile-tab">
                                                            <div class="container">
                                                                <div class="row">
                                                                    <div class="col-11 d-flex">
                                                                        <div class="col-xs-1-12">
                                                                            <div class="add_plus">
                                                                                <i class="las la-plus">
                                                                                    <br></br>
                                                                                    <small>CREATE</small>
                                                                                </i>
                                                                            </div>
                                                                        </div>

                                                                        <div class="imgcircle">
                                                                            <img src="images/page-img/24.jpg" class="img-thumbnail m-2" alt="Responsive image" />
                                                                        </div>
                                                                        <div class="imgcircle">
                                                                            <img src="images/page-img/22.jpg" class="img-thumbnail m-2" alt="Responsive image" />
                                                                        </div>
                                                                        <div class="imgcircle">
                                                                            <img src="images/page-img/17.jpg" class="img-thumbnail m-2" alt="Responsive image" />
                                                                        </div>
                                                                    </div>
                                                                    <div class="col-1"></div>
                                                                </div>
                                                            </div>
                                                            <div class="container">
                                                                <div class="row">
                                                                    <div class="col-11 d-flex ">
                                                                        <div class="imgcircle">
                                                                            <img src="images/page-img/20.jpg" class="img-thumbnail mt-2" alt="Responsive image" />
                                                                        </div>
                                                                        <div class="imgcircle">
                                                                            <img src="images/page-img/18.jpg" class="img-thumbnail m-2" alt="Responsive image" />
                                                                        </div>
                                                                        <div class="imgcircle">
                                                                            <img src="images/page-img/15.jpg" class="img-thumbnail m-2" alt="Responsive image" />
                                                                        </div>
                                                                        <div class="imgcircle">
                                                                            <img src="images/page-img/23.jpg" class="img-thumbnail m-2" alt="Responsive image" />
                                                                        </div>
                                                                    </div>
                                                                    <div class="col-1"></div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-1"></div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-4">
                                {/* <div class="iq-card iq-card-block iq-card-stretch iq-card-height audio_script"> */}
                                <div class="iq-card audio_script">
                                    <div class="iq-card-body right_card">
                                        {/* <div class="iq-card-body"> */}

                                        <ul class="nav nav-pills mb-3 twobtn" id="pills-tab" role="tablist">
                                            <li class="nav-item btnwidth">
                                                <a class="nav-link active btncss scr_audicss text-dark" id="pills-home-tab" data-toggle="pill" href="#pills-home" role="tab" aria-controls="pills-home" aria-selected="true">
                                                    <i class="lab la-facebook-messenger"></i> Script
                                                </a>
                                            </li>
                                            <li class="nav-item btnwidth">
                                                <a class="nav-link btncss scr_audicss text-dark" id="pills-profile-tab" data-toggle="pill" href="#pills-profile" role="tab" aria-controls="pills-profile" aria-selected="false">
                                                    <i class="las la-cloud-upload-alt"></i> Audio
                                                </a>
                                            </li>
                                        </ul>
                                        <div class="tab-content" id="pills-tabContent-2">
                                            <div class="tab-pane fade show active " id="pills-home" role="tabpanel" aria-labelledby="pills-home-tab">
                                                <div class="d-block pt-2 script_css">
                                                    <textarea class="form-control" value="I am an AI generated avatar created for Jason Stewart. We are building a cutting edge video generation platform that hosts lots of avatars like me. Video AI is the revolutionary new software that makes video creation fast, easy and affordable." name="address" rows="5" style={{ lineHeight: "22px", border: "none" }}>
                                                        {/* Type your script here, you can start from scratch or use our script-generation feature below. */}
                                                    </textarea>

                                                    {/* <p>Type your script here, you can start from scratch or use our script-generation feature below. </p> */}

                                                    <div class="btn-group btn-group-lg pb-3 pl-2 icon_bgcss" role="group" aria-label="Basic example">
                                                        <button type="button" class="btn btn-white">
                                                            <i class="las la-volume-up"></i>
                                                        </button>
                                                        <button type="button" class="btn btn-white">
                                                            <i class="las la-stopwatch"></i>
                                                        </button>
                                                        <button type="button" class="btn btn-white">
                                                            <i class="las la-microphone-alt"></i>
                                                        </button>
                                                    </div>
                                                </div>

                                                <div class="container mt-3">
                                                    <div class="row">
                                                        <div class="col-md-12 p-0">
                                                            <div class="dropdown">
                                                                <button id="dLabel" class="dropdown-select w-100 btn-outline-info" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                                    &nbsp;&nbsp;&nbsp;<img src="images/small/flag-01.png" /> English (united...)<i class="las la-angle-down float-right mt-2"></i>
                                                                    <span class="caret"></span>
                                                                </button>
                                                                <ul class="dropdown-menu drop_menu w-100  p-1" aria-labelledby="dLabel">
                                                                    <li>Hindi</li>
                                                                    <li>Spanish</li>
                                                                    <li>Greek</li>
                                                                </ul>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div class="container mt-2">
                                                    <div class="row">
                                                        <div class="col-md-12 p-0">
                                                            <div class="dropdown">
                                                                <button id="dLabel" class="dropdown-select w-100 btn-outline-info" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                                    &nbsp;&nbsp;&nbsp;<i class="las la-user-astronaut"></i> Jenny <i class="las la-angle-down float-right mt-2"></i>
                                                                    <span class="caret"></span>
                                                                </button>
                                                                <ul class="dropdown-menu drop_menu w-100  p-1" aria-labelledby="dLabel">
                                                                    <li>John</li>
                                                                    <li>Michel</li>
                                                                    <li>Criss</li>
                                                                </ul>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div class="container mt-2">
                                                    <div class="row">
                                                        <div class="col-md-12 p-0">
                                                            <div class="dropdown">
                                                                <button id="dLabel" class="dropdown-select w-100 btn-outline-info text" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                                    &nbsp;&nbsp;&nbsp;<i class="las la-microphone"></i> Assistant<i class="las la-angle-down float-right mt-2"></i>
                                                                    <span class="caret"></span>
                                                                </button>
                                                                <ul class="dropdown-menu w-100 drop_menu p-1" aria-labelledby="dLabel">
                                                                    <li>Assistant 1</li>
                                                                    <li>Assistant 2</li>
                                                                    <li>Assistant 3</li>
                                                                </ul>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="tab-pane fade" id="pills-profile" role="tabpanel" aria-labelledby="pills-profile-tab">
                                                <div class="card iq-mb-2 mt-2 border-primary audio_cardcss">
                                                    <div class="card-body text-dark">
                                                        <h4 class="card-title text-info">
                                                            <i class="las la-cloud-upload-alt uploadicon "></i> <b> Upload your own voice</b>
                                                        </h4>
                                                        <p class="card-text pl-2">Create more realistic videos by uploading your own voice.</p>
                                                    </div>
                                                </div>
                                                <div class="card iq-mb-3 mt-2 border-primary audio_cardcss">
                                                    <div class="card-body text-dark">
                                                        <h4 class="card-title text-info">
                                                            <span>
                                                                {" "}
                                                                <i class="las la-microphone micro"></i>
                                                            </span>
                                                            <b>Record voice audio</b>
                                                        </h4>
                                                        <p class="card-text pl-2">
                                                            <a href="#">
                                                                <u>Contact us</u>
                                                            </a>{" "}
                                                            to learn about our Enterprise plan.
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>{" "}
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
